import api from "@api";
import router from "@/router";
import Swal from "sweetalert2";
export default {
    state: {
        contacts:[],
        contactDto:{
            id:"",
            name:"",
            email:"",
            subject:"",
            description:"",
            reply:"",
            sendDate: new Date(),
            replyDate: null
        }
    },
    mutations: {
        GET_CONTACTS(state, payload)
        {
            state.contacts = payload;
        },
        GET_CONTACT_DETAILS(state, payload)
        {
            Object.assign(state.contactDto,payload);
        },
        SET_REPLY(state, payload)
        {
            Object.assign(state.contactDto,payload);
        },
        REMOVE_CONTACT()
        {
            router.push("/admin/contacts");
            Swal.fire({
                title: "تم الحذف!",
                text: `تم حذف الرسالة بنجاح .`,
                icon: "success",
                showConfirmButton: false,
                timer: 2000
              });
        },
        REMOVE_MULTI_CONTACTS(state, payload)
        {
            let MapOfIds = new Map();
            var idx;
            var tempList = [];
            for (idx = 0; idx < payload.length; idx++) {
              MapOfIds.set(payload[idx], 1);
            }
            for (idx = 0; idx < state.contacts.length; idx++) {
              if (MapOfIds.has(state.contacts[idx].id) === false) {
                tempList.push(state.contacts[idx]);
              }
            }
            state.contacts = tempList;
            // Swal.fire({
            //     title: "تم الحذف!",
            //     text: `تم حذف البريد المحدد بنجاح .`,
            //     icon: "success",
            //     showConfirmButton: false,
            //     timer: 2000
            //   });
        }
    },
    actions: {
        async fetchContacts({ commit })
        {
            await api.get("Contact/Fetch", ({ data }) => {
                commit("GET_CONTACTS", data);
            });
        },
        async getContactDetails({commit}, id)
        {
            await api.get("Contact/GetById?id="+ id, ({ data }) => {
                commit("GET_CONTACT_DETAILS", data);
            });
        },
        async setReply({commit}, payload)
        {
            await api.put("Contact/SetReply",payload, ({ data }) => {
                commit("SET_REPLY", data);
            },{success: 'تم الرد على الرسالة بنجاح', error: "فشل الرد على الرسالة"});
        },
        async deleteContact({commit}, id)
        {
            await api.delete("Contact/Delete?id="+id,() => {
                commit("REMOVE_CONTACT", id);
            },{
                confirm: 'هل تريد فعلاً حذف الرسالة؟', success: 'تم حذف الرسالة بنجاح',
                error: "فشل حذف الرسالة"
            });
        },
        async deleteMultiContacts({commit}, ids){
            await api.delete("Contact/DeleteMulti",() => {
                commit("REMOVE_MULTI_CONTACTS", ids);
            },{
                confirm: 'هل تريد فعلاً حذف الرسائل المحددة؟', success: 'تم حذف الرسائل المحددة بنجاح',
                error: "فشل حذف الرسائل المحددة"
            },ids);
        }
    }
}