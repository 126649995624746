import { Admin, Editor } from "@/router";
export default [
    {
        path: "employees",
        name: "employees",
        components: {
            default: () => import("@/views/admin/employee"),
            actions: () =>
                import(
                    "@/views/admin/employee/components/create-employee-button.vue"
                )
        },
        props: {
            default: true
        },
        meta: () => ({
            pageTitle: "الموظفين",
            roles: [Admin, Editor],
            layout: "vertical",
            breadcrumb: [
                {
                    text: "الموظفين",
                    icon: "users-alt",
                    active: true
                }
            ]
        })
    },
    {
        path: "employees/:id",
        name: "employee details",
        component: () => import("@/views/admin/employee/details.vue"),
        props: true,
        meta: () => ({
            pageTitle: "الموظفين",
            roles: [Admin, Editor],
            layout: "vertical",
            breadcrumb: [
                {
                    text: "الموظفين",
                    active: false,
                    icon: "users-alt",
                    to: "/admin/employees"
                },
                {
                    text: "تفاصيل الموظف",
                    active: true
                }
            ]
        })
    }
];
