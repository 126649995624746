import api from "@api";
import router from "@/router";
import store from "@/store";
export default {
    state: {
        services:[],
        serviceDto:{
            id:"",
            name:"",
            description:"",
            documents:[],
            documentIds:[],
            language: 0,
        }
    },
    mutations:{
        GET_SERVICES(state, payload)
        {
            state.services = payload;
        },
        GET_SERVICE_DETAILS(state, payload)
        {
            Object.assign(state.serviceDto, payload);
        },
        ADD_SERVICE(state, payload)
        {
            state.services.unshift(payload);
        },
        RESET_SERVICE_FORM(state) {
            state.serviceDto = {
                id: "",
                name: "",
                description: "",
                documents: [],
                language: 0,
            }
        }
    },
    actions:{
        async fetchServices({commit})
        {
            await api.get("Service/GetAll?language="+store.state.app.dashLang,({data}) => {
                commit("GET_SERVICES", data);
            });
        },
        async getServiceDetails({commit}, id)
        {
            await api.get(`Service/GetById?id=${id}&language=${store.state.app.dashLang}`,({data}) => {
               commit("GET_SERVICE_DETAILS", data);
            });
        },
        async addService({commit}, payload)
        {
            let formData = new FormData();

            formData.append('name', payload.name);
            formData.append('description', payload.description);

            var index = 0;
            for (var doc of payload.documents) {
                formData.append("documents[" + index + "].id", doc.id);
                formData.append("documents[" + index + "].path", doc.path);
                formData.append("documents[" + index + "].file", doc.file);
                index++;
            }

            await api.post("Service/Add",formData,({data}) => {
                commit("ADD_SERVICE", data);
            },{success:"تم إضافة الخدمة بنجاح",error:"فشل في إضافة الخدمة"});
        },
        async updateService(ctx, payload)
        {
            let formData = new FormData();

            formData.append('id', payload.id);
            formData.append('name', payload.name);
            formData.append('description', payload.description);
            formData.append('language', payload.language);

            var Index = 0;
            for (var id of payload.documentIds) {
                formData.append("documentIds[" + Index + "]", id);
                Index++;
            }

            var documentsIndex = 0;
            for (var doc of payload.documents) {
                formData.append("documents[" + documentsIndex + "].id", doc.id);
                formData.append("documents[" + documentsIndex + "].path", doc.path);
                formData.append("documents[" + documentsIndex + "].file", doc.file);
                documentsIndex++;
            }

            await api.put("Service/Update", formData, () => {
            }, { success: "تم تعديل الخدمة بنجاح", error: "فشل في تعديل الخدمة" });
        },
        async deleteService(ctx, id)
        {
            api.delete(
                "Service/Remove?id=" + id,
                ({ data }) => {
                    if (data) {
                        router.push("./");
                    }
                }, { confirm: "هل فعلاً تريد حذف الخدمة؟", success: "تم حذف الخدمة بنجاح", error: "فشل في حذف الخدمة" }
            );
        }
    }
}