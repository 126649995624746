import { Admin, Editor } from "@/router";
export default [
    {
        path: "services",
        name: "services",
        components: {
            default: () =>
                import("@/views/admin/service"),
            "actions": () =>
                import("@/views/admin/service/components/service-action-button.vue"),
        },
        props: {
            default: true,
            actions: true,
        },
        meta: () => ({
            pageTitle: "الخدمات",
            roles: [Admin, Editor],
            layout: "vertical",
            breadcrumb: [
                {
                    text: "الخدمات",
                    icon: "briefcase-alt",
                    active: true,
                },
            ],
        }),
    },
    {
        path: "services/:id",
        name: "service details",
        component: () =>
            import(
                "@/views/admin/service/details.vue"
            ),
        props: true,
        meta: () => ({
            pageTitle: "الخدمات",
            layout: "vertical",
            roles: [Admin, Editor],
            breadcrumb: [
                {
                    text: "الخدمات",
                    to: "/admin/services",
                    icon: "briefcase-alt",
                    active: false,
                },
                {
                    text: "تفاصيل الخدمة",
                    active: true,
                },
            ],
        }),
    },

]