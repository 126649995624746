import axiosIns from "@/libs/axios";
import store from "@/store";
import api from "@api";
import { setCookie, getCookie } from "@Ekcore/util/global";
import { toast } from "@/libs/vue-toast.js";
import Swal from "sweetalert2";

export default {
    state: {
        cartProducts: [],
        cartCount: 0,
        setOrderDto: {
            id: "",
            orderDate: "",
            note: "",
            totalPrice: 0,
            orderDetails: []
        }
    },
    getters: {
        totalPrice({ cartProducts }, _, { global }) {
            let cur = global.currType;
            if (!cur)
                return cartProducts.reduce((prev, cp) => {
                    return (
                        prev +
                        cp.attribute.reduce((pre, curr) => {
                            return cp.quantity * curr.price + pre;
                        }, cp.price * cp.quantity)
                    );
                }, 0);
            else
                return cartProducts.reduce((prev, cp) => {
                    return (
                        prev +
                        cp.attribute.reduce((pre, curr) => {
                            return cp.quantity * curr.dollarPrice + pre;
                        }, cp.dollarPrice * cp.quantity)
                    );
                }, 0);
        }
    },
    mutations: {
        SET_CART_ITEM(state, payload) {
            console.log(payload);
            setCookie("awijaCart", payload.hashedData);
            store.dispatch("getCartCount");
        },
        GET_CART_ITEM(state, pytload) {
            state.cartProducts = pytload.cartItems;
        },
        GET_CART_COUNT(state, pytload) {
            state.cartCount = pytload;
        },
        REMOVE_CART_ITEM(state, id) {
            state.cartProducts.splice(
                state.cartProducts.findIndex(pr => pr.id == id),
                1
            );
        },
        Set_New_order(state) {
            setCookie("awijaCart", "", 1);
            store.dispatch("getCartCount");
            state.cartProducts = [];
            Swal.fire({
                title: "تم ارسال الطلبية",
                text:
                    "شكراً لطلبكم، يتم معالجة الطلب الآن من قبل الإدارة وسيتم التواصل معكم في أقرب فرصة",
                icon: "success",
                showConfirmButton: false,
                timer: 2000
            });
        }
    },
    actions: {
        setNewOrder({ commit }, payload) {
            console.log(payload);
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: "btn btn-success",
                    cancelButton: "btn btn-danger"
                },
                buttonsStyling: true
            });
            swalWithBootstrapButtons
                .fire({
                    title: "تنبيه!",
                    text: "هل انت متأكد من إرسال الطلبية",
                    icon: "info",
                    showCancelButton: true,
                    confirmButtonText: "نعم",
                    cancelButtonText: "إلغاء",
                    reverseButtons: false
                })
                .then(result => {
                    if (result.value) {
                        axiosIns
                            .post("Order/Add", payload)
                            .then(({ data }) => {
                                commit("Set_New_order", data);
                            })
                            .catch(err => {
                                if (err.response.status == 401) {
                                    toast.open({
                                        message:
                                            "يجب تسجيل الدخول للتمكن من اتمام الطلب",
                                        position: "top-right",
                                        type: "error",
                                        duration: 2000,
                                        dismissible: true
                                    });
                                } else {
                                    toast.open({
                                        message:
                                            'حدث خطأ غير متوقع الرجاء المحاولة مجددا"',
                                        position: "top-right",
                                        type: "error",
                                        duration: 2000,
                                        dismissible: true
                                    });
                                }
                            });
                    }
                });
        },
        addToCart({ commit }, payload) {
            api.post(
                "Cart/SetToCart",
                payload,
                ({ data }) => {
                    commit("SET_CART_ITEM", data);
                },
                { success: "تم إضافة المنتج للسلة" }
            );
        },
        updateCart({ commit }, payload) {
            api.post(
                "Cart/SetToCart",
                payload,
                ({ data }) => {
                    commit("SET_CART_ITEM", data);
                },
                { success: "تم تعديل الكمية بنجاح" }
            );
        },
        async getCartItems({ commit }) {
            const { data } = await axiosIns.post("Cart/GetCart", {
                hash: getCookie("awijaCart")
            });
            commit("GET_CART_ITEM", data);
        },
        async getCartCount({ commit }) {
            const { data } = await axiosIns.post("Cart/GetItemsCount", {
                hash: getCookie("awijaCart")
            });
            commit("GET_CART_COUNT", data);
        },
        removeCartItem({ commit }, id) {
            api.post(
                "Cart/RemoveCartItem?id=" + id,
                { hash: getCookie("awijaCart") },
                ({ data }) => {
                    commit("SET_CART_ITEM", { hashedData: data });
                    commit("REMOVE_CART_ITEM", id);
                },
                { success: "تم الإزالة بنجاح" },
                { error: "فشلت عملية الإزالة" }
            );
        }
    }
};
