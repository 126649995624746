import { nullGuid } from "@/EK-core/util/global";
import store from "@/store";
import api from "@api";
import jwtConf from "@/@core/auth/jwt/jwtDefaultConfig";
export default {
    state: {
        countries: [],
        contactDto: {
            id: nullGuid,
            name: "",
            email: "",
            subject: "",
            description: ""
        },
        aboutDto: {
            goals: "",
            vision: "",
            goalsImage: "",
            visionImage: ""
        },
        homeDto: {
            services: [],
            mostSellingProducts: [],
            latestActivities: [],
            clients: []
        },
        flagName: {
            value: ""
        },
        employees: [],
        clients: [],
        aboutImages: [],
        websiteSettings: {
            mobileNumber: "",
            mobileNumber2: "",
            phoneNumber: "",
            phoneNumber2: "",
            facebookLink: "",
            instagramLink: "",
            linkedinLink: "",
            behanceLink: "",
            whatsapp: "",
            email: ""
        },
        locate: {
            countryCode: "sy",
        },
        currType: 0
    },
    getters: {
        countries({ countries, flagName }) {
            return countries.filter(
                c =>
                    c.name
                        .toLowerCase()
                        .indexOf(flagName.value.toLowerCase()) != -1 ||
                    c.arabicName
                        .toLowerCase()
                        .indexOf(flagName.value.toLowerCase()) != -1
            );
        }
    },
    mutations: {
        Fetch_Countries(state, data) {
            state.countries = data;
        },
        RESET_FORM(state) {
            state.contactDto = {
                id: nullGuid,
                name: "",
                email: "",
                subject: "",
                description: ""
            };
        },
        GET_WEBSITE_ABOUT(state, payload) {
            state.aboutDto = payload.aboutDto;
            state.employees = payload.employees;
            state.clients = payload.clients;
            state.aboutImages = payload.aboutImages;
        },
        Set_Home_Page(state, payload) {
            Object.assign(state.homeDto, payload);
        },
        Log_Out() {
            store.commit("Reset_User");
            localStorage.removeItem(jwtConf.websiteStorageTokenKeyName);
            localStorage.removeItem("customerData");
        },
        Get_Website_Settings(state, payload) {
            Object.assign(state.websiteSettings, payload);
        },
        Set_Locate(state, payload) {
            Object.assign(state.locate, payload)
            if(payload.countryCode.toLowerCase() == 'sy') {
                state.currType = 0
            } else {
                state.currType = 1
            }
        }
    },
    actions: {
        fetchCountries({ commit }) {
            api.get("country/GetAll", ({ data }) => {
                 commit("Fetch_Countries", data);
            });
        },
        async setContact({ commit }, payload) {
            await api.post(
                "Website/SetContact",
                payload,
                ({ data }) => {
                    if (data) commit("RESET_FORM");
                },
                { success: "تم الإرسال بنجاح", error: "فشل الإرسال" }
            );
        },
        async getWebsiteAbout({ commit }) {
            await api.get(
                "Website/GetAboutPage?language=" + store.state.app.lang,
                ({ data }) => {
                    commit("GET_WEBSITE_ABOUT", data);
                }
            );
        },
        async getWebsiteHome({ commit }) {
            await api.get(
                "Website/GetHome?language=" + store.state.app.lang,
                ({ data }) => {
                    commit("Set_Home_Page", data);
                }
            );
        },
        async getWebsiteSettings({ commit }) {
            await api.get("Website/GetSettings", ({ data }) => {
                commit("Get_Website_Settings", data);
            });
        },
        getLocation({commit}) {
            if(localStorage.getItem('locate')) {
                commit('Set_Locate', {countryCode: localStorage.getItem('locate')})
            } else {
                api.get("https://api.db-ip.com/v2/free/self", ({ data }) => {
                    localStorage.setItem('locate', data.countryCode.toLowerCase())
                    commit('Set_Locate', {countryCode: data.countryCode})
                });
            }
        }
    }
};
