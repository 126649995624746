import api from "@api";
import router from "@/router";
import store from "@/store";
export default {
    state: {
        products: [],
        categoriesList: [],
        addonsList: [],
        productDto: {
            id: "",
            name: "",
            description: "",
            images: [],
            files: [],
            categoriesIds: [],
            limit: 0,
            limitOutside:0,
            foodSuitability: false,
            isCircleShape: false,
            language: 0,
            uses: [],
            measurementCurrencies: [],
            attributes: [],
            file3D: null,
            path3D: ""
        }
    },
    mutations: {
        GET_PRODUCTS(state, payload) {
            state.products = payload;
        },
        GET_PRODUCT_DETAILS(state, payload) {
            if (payload.productDto == null) {
                Object.assign(state.productDto, {
                    id: "",
                    name: "",
                    description: "",
                    images: [],
                    files: [],
                    categoriesIds: [],
                    limit: 0,
                    limitOutside: 0,
                    foodSuitability: false,
                    isCircleShape: false,
                    language: 0,
                    uses: [],
                    measurementCurrencies: [],
                    attributes: [],
                    file3D: null,
                    path3D: ""
                });
            } else {
                Object.assign(state.productDto, payload.productDto);
            }
            state.categoriesList = payload.categoriesList;
            state.addonsList = payload.addonsList;
        },
        UPDATE_PRODUCT(state, payload) {
            Object.assign(state.productDto, {...payload, uses: payload.uses ? payload.uses : []});
        }
    },
    actions: {
        async fetchProducts({ commit }) {
            await api.get(
                "Product/GetAll?Language=" + store.state.app.dashLang,
                ({ data }) => {
                    commit("GET_PRODUCTS", data);
                }
            );
        },
        async getProductDetails({ commit }, id) {
            await api.get(
                `Product/GetDetails?language=${store.state.app.dashLang}&id=${id}`,
                ({ data }) => {
                    commit("GET_PRODUCT_DETAILS", data);
                }
            );
        },
        async addProduct(ctx, payload) {
            let formData = new FormData();

            formData.append("name", payload.name);
            formData.append("description", payload.description);
            formData.append("limit", payload.limit);
            formData.append("limitOutside", payload.limitOutside);
            formData.append("foodSuitability", payload.foodSuitability);
            formData.append("language", payload.language);
            formData.append("isCircleShape", payload.isCircleShape);
            payload.uses.forEach((use, index) => {
                formData.append("uses[" + index + "].id", use.id);
                formData.append("uses[" + index + "].use", use.use);
            });

            payload.categoriesIds.forEach((cat, index) => {
                formData.append("categoriesIds[" + index + "]", cat);
            });

            payload.files.forEach(file => {
                formData.append("files", file);
            });

            payload.measurementCurrencies.forEach((mc, index) => {
                formData.append(
                    "measurementCurrencies[" + index + "].dollarValue",
                    mc.dollarValue
                );
                formData.append(
                    "measurementCurrencies[" + index + "].length",
                    mc.length
                );
                formData.append(
                    "measurementCurrencies[" + index + "].width",
                    mc.length
                );
                formData.append(
                    "measurementCurrencies[" + index + "].height",
                    mc.length
                );
                formData.append(
                    "measurementCurrencies[" + index + "].file",
                    mc.file
                );

                if (mc.syrianValue)
                    formData.append(
                        "measurementCurrencies[" + index + "].syrianValue",
                        mc.syrianValue
                    );
                if (mc.capacityLiter)
                    formData.append(
                        "measurementCurrencies[" + index + "].capacityLiter",
                        mc.capacityLiter
                    );
                if (mc.capacityKilo)
                    formData.append(
                        "measurementCurrencies[" + index + "].capacityKilo",
                        mc.capacityKilo
                    );
            });

            payload.attributes.forEach((attr, index) => {
                formData.append("attributes[" + index + "].id", attr.id);
                formData.append(
                    "attributes[" + index + "].attributeId",
                    attr.attributeId
                );
                if (attr.syrianValue)
                    formData.append(
                        "attributes[" + index + "].syrianValue",
                        attr.syrianValue
                    );
                formData.append(
                    "attributes[" + index + "].dollarValue",
                    attr.dollarValue
                );
            });
            if (payload.file3D) formData.append("file3D", payload.file3D);
            formData.append("path3D", payload.path3D);

            await api.post(
                "Product/Add",
                formData,
                () => {
                    router.push("./");
                },
                { success: "تم إضافة منتج بنجاح", error: "فشل إضافة منتج" }
            );
        },
        async updateProduct({ commit }, payload) {
            
            let formData = new FormData();

            formData.append("id", payload.id);
            formData.append("name", payload.name);
            formData.append("description", payload.description);
            formData.append("limit", payload.limit);
            formData.append("limitOutside", payload.limitOutside);
            formData.append("foodSuitability", payload.foodSuitability);
            formData.append("language", payload.language);
            console.log("payload.uses", payload.uses)
            formData.append("isCircleShape", payload.isCircleShape);
            payload.uses?.forEach((use, index) => {
                formData.append("uses[" + index + "].id", use.id);
                formData.append("uses[" + index + "].use", use.use);
            });

            payload.categoriesIds.forEach((cat, index) => {
                formData.append("categoriesIds[" + index + "]", cat);
            });

            payload.files?.forEach(file => {
                formData.append("files", file);
            });

            payload.images.forEach((img, index) => {
                formData.append("images[" + index + "].id", img.id);
                formData.append("images[" + index + "].path", img.path);
            });

            payload.measurementCurrencies.forEach((mc, index) => {
                if (mc.id)
                formData.append(
                    "measurementCurrencies[" + index + "].id",
                    mc.id
                );
                formData.append(
                    "measurementCurrencies[" + index + "].dollarValue",
                    mc.dollarValue
                );
                formData.append(
                    "measurementCurrencies[" + index + "].length",
                    mc.length
                );
                formData.append(
                    "measurementCurrencies[" + index + "].height",
                    mc.height
                );
                formData.append(
                    "measurementCurrencies[" + index + "].width",
                    mc.width
                );
                formData.append(
                    "measurementCurrencies[" + index + "].file",
                    mc.file
                );
                formData.append(
                    "measurementCurrencies[" + index + "].path",
                    mc.path
                );
                if (mc.syrianValue)
                    formData.append(
                        "measurementCurrencies[" + index + "].syrianValue",
                        mc.syrianValue
                    );
                if (mc.capacityLiter)
                    formData.append(
                        "measurementCurrencies[" + index + "].capacityLiter",
                        mc.capacityLiter
                    );
                if (mc.capacityKilo)
                    formData.append(
                        "measurementCurrencies[" + index + "].capacityKilo",
                        mc.capacityKilo
                    );
            });

            payload.attributes?.forEach((attr, index) => {
                formData.append("attributes[" + index + "].id", attr.id);
                formData.append(
                    "attributes[" + index + "].attributeId",
                    attr.attributeId
                );
                if (attr.syrianValue)
                    formData.append(
                        "attributes[" + index + "].syrianValue",
                        attr.syrianValue
                    );
                formData.append(
                    "attributes[" + index + "].dollarValue",
                    attr.dollarValue
                );
            });

            if (payload.file3D) formData.append("file3D", payload.file3D);
            formData.append("path3D", payload.path3D);
            await api.post(
                "Product/Update",
                formData,
                ({ data }) => {
                    commit("UPDATE_PRODUCT", data);
                },
                { success: "تم تعديل المنتج بنجاح", error: "فشل تعديل المنتج" }
            );
        },
        async deleteProduct(ctx, id) {
            await api.delete(
                "Product/Remove?Id=" + id,
                ({ data }) => {
                    if (data) {
                        router.push("./");
                    }
                },
                {
                    confirm: "هل فعلاً تريد حذف المنتج؟",
                    success: "تم حذف المنتج بنجاح",
                    error: "فشل في حذف المنتج"
                }
            );
        }
    }
};
