import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import filter from './global-store/filter'

import admin from "./admin/index"
import website from "./website/index"

Vue.use(Vuex)
export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    filter,

    ...admin,
    ...website
  },
  strict: process.env.DEV,
})
