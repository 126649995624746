import api from "@api";
import { nullGuid } from "@Ekcore/util/global.js";
import store from "@/store";
// import router from "@/router";
export default {
    state: {
        mainCategoriesList: [],
        subCategoriesList: [],
        categoryDto: {
            id: nullGuid,
            name: "",
            imagePath: "",
            dateCreated: "",
            imageFile: "",
            description: "",
            parentCategoryId: "",
            categoriesChildrenCount: "",
            language: ""
        },
        parentCategoryId: null
    },

    mutations: {
        Rest_Parent_Category_Id(state, payload) {

            
            if(state.parentCategoryId == payload)
            {
                state.parentCategoryId = null;
            }
            
        },
        Rest_Range_Parent_Category_Id(state, payload) {

            payload.forEach(el => {
                if(el == state.parentCategoryId)
                {
                    state.parentCategoryId = null;
                }
            })
        },
        Set_Parent_Category_Id(state, payload) {
            state.parentCategoryId = payload;
        },
        Get_Category_List(state, payload) {
            state.mainCategoriesList = [...payload];
        },
        Get_Sub_Categories_List(state, payload) {
            state.subCategoriesList = [...payload];
        },
        Update_Category(state, payload) {
            Object.assign(state.categoryDto, payload);
        },
        Update_Category_List(state, payload) {
            Object.assign(
                state.mainCategoriesList.find(ac => ac.id == payload.id),
                payload
            );
        },
        Update_Sub_Categories_List(state, payload) {


            let oldParentId = state.subCategoriesList.find(ac => ac.id == payload.id).parentCategoryId;
            
            Object.assign(
                state.subCategoriesList.find(ac => ac.id == payload.id),
                payload
            );

            state.subCategoriesList = state.subCategoriesList.filter(e => {
                if(e.parentCategoryId == state.parentCategoryId)
                    {
                        return e;
                    }
                    else
                    {
                        state.mainCategoriesList = state.mainCategoriesList.map(x => {
                            if(x.id == e.parentCategoryId)
                            {
                                return Object.assign({}, x, {
                                    categoriesChildrenCount: x.categoriesChildrenCount + 1,
                                })
                            }
                            else
                            if(x.id == oldParentId)
                            {
                                return Object.assign({}, x, {
                                    categoriesChildrenCount: x.categoriesChildrenCount - 1
                                })
                            }
                            else
                            {
                                return x;
                            }
                        })
                    }
            })
            
        },
        Update_Category_Sub_Categoreies(state, categoryId) {
            state.subCategoriesList = state.subCategoriesList.filter(
                e => e.id != categoryId
            );
        },
        Reset_Category_Dto(state, payload) {
            if (payload) {
                Object.assign(state.categoryDto, payload);
            } else {
                Object.assign(state.categoryDto, {
                    id: nullGuid,
                    name: "",
                    imagePath: "",
                    dateCreated: "",
                    imageFile: "",
                    description: "",
                    parentCategoryId: "",
                    categoriesChildrenCount: "",
                    language: ""
                });
            }
        },
        Reset_Sub_Category_List(state) {
            state.subCategoriesList = [];
        },
        Increase_Main_Catogory_Count(state, payload) {
            state.mainCategoriesList = state.mainCategoriesList.map(el => {
                if (el.id == payload.parentCategoryId) {
                    return Object.assign({}, el, {
                        categoriesChildrenCount: el.categoriesChildrenCount + 1
                    });
                }

                return el;
            });
        },
        Add_Category(state, payload) {
            state.mainCategoriesList.unshift(payload);
        },
        Delete_Category(state, id) {
            state.mainCategoriesList.splice(
                state.mainCategoriesList.findIndex(item => item.id == id),
                1
            );

            if (state.subCategoriesList.length > 0) {
                state.subCategoriesList = state.subCategoriesList.filter(
                    sub => sub.parentCategoryId != id
                );
            }
        },
        Delete_Range_Category(state, ids) {
            state.mainCategoriesList = state.mainCategoriesList.filter(
                category => !ids.includes(category.id)
            );

            if (state.subCategoriesList.length > 0)
                ids.forEach(id => {
                    if (state.subCategoriesList[0].parentCategoryId == id) {
                        state.subCategoriesList = state.subCategoriesList.filter(
                            sub => sub.parentCategoryId != id
                        );
                    }
                });
        },
        Add_SubCategory(state, payload) {
                        
            if(payload.parentCategoryId == state.parentCategoryId)
            {
                state.subCategoriesList.unshift(payload.data);
            }

        },
        Delete_Sub_Category(state, payload) {
            state.subCategoriesList.splice(
                state.subCategoriesList.findIndex(item => item.id == payload.id),
                1
            );


            state.mainCategoriesList = state.mainCategoriesList.map(el => {
                if (el.id == payload.parentCategoryId) {
                    return Object.assign({}, el, {
                        categoriesChildrenCount:
                            el.categoriesChildrenCount - 1
                    });
                }
                return el;
            });

            
        },
        Delete_Range_Sub_Category(state, payload) {
            state.subCategoriesList = state.subCategoriesList.filter(
                sub => !payload.ids.includes(sub.id)
            );

            state.mainCategoriesList = state.mainCategoriesList.map(el => {
                if (el.id == payload.parentId) {
                    return Object.assign({}, el, {
                        categoriesChildrenCount:
                            el.categoriesChildrenCount - payload.ids.length
                    });
                }

                return el;
            });
        }
    },
    actions: {
        getMainCategories({ commit }) {
            api.get("Category/GetRoots?language="+store.state.app.dashLang, ({ data }) => {
                commit("Get_Category_List", data);
            });
        },
        getSubCategories({ commit }, payload) {
            api.get(`Category/GetChildren?language=${store.state.app.dashLang}&parentId=${payload}`, ({ data }) => {
                commit("Get_Sub_Categories_List", data);
            });
        },
        addCategory({ commit }, payload) {
            const config = {
                headers: { "Content-Type": "multipart/form-data" }
            };

            api.post(
                "Category/Add",
                payload,
                ({ data }) => {
                    commit("Add_Category", data);
                },
                { success: "تمت الإضافة بنجاح", error: "فشل الإضافة" },
                config
            );
        },
        updateCategory({ commit }, payload) {
            api.put(
                "Category/Update",
                payload,
                ({ data }) => {
                    commit("Update_Category_List", data);
                },
                { success: "تم التعديل بنجاح", error: "فشل التعديل" }
            );
        },
        deleteCategory({ commit }, id) {
            api.delete(
                "Category/Remove?id=" + id,
                ({ data }) => {
                    if (data) {
                        commit("Rest_Parent_Category_Id", id);
                        commit("Delete_Category", id);
                        commit("Reset_Category_Dto");

                    }
                },
                {
                    confirm: "هل تريد فعلا حذف التصنيف",
                    success: "تم حذف التصنيف بنجاح",
                    error: "فشل حذف التصنيف"
                }
            );
        },
        deleteRangeCategoryList({ commit }, ids) {
            api.delete(
                "Category/RemoveRange",
                ({ data }) => {
                    if (data) {
                        commit("Rest_Range_Parent_Category_Id", ids)
                        commit("Delete_Range_Category", ids);
                        commit("Reset_Category_Dto");

                    }
                },
                {
                    confirm: "هل تريد فعلا حذف التصنيف",
                    success: "تم حذف التصنيف بنجاح",
                    error: "فشل حذف التصنيف"
                },
                ids
            );
        },
        addSubCategory({ commit }, payload) {
            const config = {
                headers: { "Content-Type": "multipart/form-data" }
            };

            api.post(
                "Category/Add",
                payload,
                ({ data }) => {
                    commit("Add_SubCategory", {data, parentCategoryId: data.parentCategoryId});
                    commit("Increase_Main_Catogory_Count", data);
                    commit("Reset_Category_Dto");
                },
                { success: "تمت الإضافة بنجاح", error: "فشل الإضافة" },
                config
            );
        },
        updateSubCategory({ commit }, payload) {
            api.put(
                "Category/Update",
                payload,
                ({ data }) => {
                    commit("Update_Sub_Categories_List", data);
                    commit("Reset_Category_Dto");
                },
                { success: "تم التعديل بنجاح", error: "فشل التعديل" }
            );
        },
        deleteSubCategory({ commit }, payload) {
            api.delete(
                "Category/Remove?id=" + payload.id,
                ({ data }) => {
                    if (data) {
                        commit("Delete_Sub_Category", payload);
                        commit("Reset_Category_Dto");
                    }
                },
                {
                    confirm: "هل تريد فعلا حذف التصنيف",
                    success: "تم حذف التصنيف بنجاح",
                    error: "فشل حذف التصنيف"
                }
            );
        },
        deleteRangeSubCategory({ commit }, payload) {
            api.delete(
                "Category/RemoveRange",
                ({ data }) => {
                    if (data) {
                        commit("Delete_Range_Sub_Category", payload);
                        commit("Reset_Category_Dto");
                    }
                },
                {
                    confirm: "هل تريد فعلا حذف التصنيف",
                    success: "تم حذف التصنيف بنجاح",
                    error: "فشل حذف التصنيف"
                },
                payload.ids
            );
        }
    }
};
