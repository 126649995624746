import { All } from "@/router";
export default [
    {
        path: "",
        name: "website",
        component: () => import("@/views/website/pages/main"),
        meta: () => ({
            roles: [All],
            title:'home'
        })
    },
    {
        path: "services",
        name: "website services",
        component: () => import("@/views/website/pages/service/index"),
        meta: () => ({
            roles: [All],
            title:'services'
        })
    },
    {
        path: "categories",
        name: "website categories",
        component: () => import("@/views/website/pages/products/categories"),
        meta: () => ({
            roles: [All],
            title:'categories'

        })
    },
    {
        path: "category/:id",
        name: "website category details",
        component: () => import("@/views/website/pages/products/category-details"),
        props: true,
        meta: () => ({
            roles: [All],
            title:'categories'
        })
    },
    {
        path: "products",
        name: "website products",
        props: true,
        component: () => import("@/views/website/pages/products/products"),
        meta: () => ({
            roles: [All],
            title:'allproducts'
        })
    },
    {
        path: "products/:id",
        name: "website products details",
        props: true,
        component: () => import("@/views/website/pages/products/product-details"),
        meta: () => ({
            title:'ourproducts',
            roles: [All],
        })
    },
    {
        path: "aboutus",
        name: "website aboutus",
        component: () => import("@/views/website/pages/aboutus/index"),
        meta: () => ({
            title:'aboutus',
            roles: [All]
        })
    },
    {
        path: "activities",
        name: "website activities",
        component: () => import("@/views/website/pages/activities/index"),
        meta: () => ({
            roles: [All],
            title:'activities'
        })
    },

    {
        path: "activities/:id",
        name: "website activity details",
        component: () => import("@/views/website/pages/activities/pages/details"),
        props: true,
        meta: () => ({
            roles: [All],
            title:'activityDetails'
        })
    },
    {
        path: "contactus",
        name: "Contact Us",
        component: () => import("@/views/website/pages/contactus/index"),
        meta: () => ({
            roles: [All],
            title:'contactus'
        })
    },
    {
        path: "cart",
        name: "cart",
        component: () => import("@/views/website/pages/cart/index"),
        meta: () => ({
            roles: [All],
            title:'cart'
        })
    },
    {
        path: "auth-signin",
        name: "website signin",
        component: () => import("@/views/website/pages/auth/signin"),
        props: true,
        meta: () => ({
            roles: [All],
            pageTitle: "website.login",
            description: "root website cooming soon",
            keywords: 'root, design, designer, cooming',
            title:'signin'

        })
    },
    {
        path: "auth-signup",
        name: "website signup",
        component: () => import("@/views/website/pages/auth/signup"),
        props: true,
        meta: () => ({
            roles: [All],
            pageTitle: "website.signup",
            description: "root website cooming soon",
            keywords: 'root, design, designer, cooming',
            title:'signup'
        })
    },
    {
        path: "profile/:id",
        name: "website profile",
        component: () => import("@/views/website/pages/auth/profile"),
        props: true,
        meta: () => ({
            roles: [All],
            pageTitle: "website.profile",
            title:'profile',
            description: "root website cooming soon",
            keywords: 'root, design, designer, cooming'
        })
    },
];
