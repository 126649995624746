import api from "@api"
import router from "@/router";
import store from "@/store";
export default {
    state: {
        employees:[],
        positionsList:[],
        employeeDto:{
            id:"",
            name:"",
            positionId:0,
            langauge:0,
            imageDto:{
            }
        }
    },
    mutations: {
        GET_EMPLOYEES(state, payload) {
            state.employees = payload.employees;
            state.positionsList = payload.positionsList;
        },
        GET_EMPLOYEE_DETAILS(state, payload)
        {
            Object.assign(state.employeeDto, payload.employeeDto);
            state.positionsList = payload.positionsList;
        },
        ADD_EMPLOYEE(state, payload)
        {
            payload.positionName = state.positionsList.find(item => item.id == payload.positionId).name;
            state.employees.unshift(payload);
        },
        DELETE_MULTI_EMPLOYEES(state, payload)
        {
            let MapOfIds = new Map();
            var idx;
            var tempList = [];
            for (idx = 0; idx < payload.length; idx++) {
              MapOfIds.set(payload[idx], 1);
            }
            for (idx = 0; idx < state.employees.length; idx++) {
              if (MapOfIds.has(state.employees[idx].id) === false) {
                tempList.push(state.employees[idx]);
              }
            }
            state.employees = tempList;
        },
        RESET_EMPLOYEE_FORM(state) {
            state.employeeDto = {
                id: "",
                name: "",
                positionId: 0,
                langauge: 0,
                imageDto: {
                }
            }
        }
    },
    actions: {
        async fetchEmployees({ commit }) {
            await api.get("Employee/GetAll?language="+store.state.app.dashLang,({data}) => {
                commit("GET_EMPLOYEES", data);
            })
        },
        async getEmployeeDetails({ commit }, id) {
            await api.get(`Employee/GetById?employeeId=${id}&language=${store.state.app.dashLang}`, ({ data }) => {
                commit("GET_EMPLOYEE_DETAILS", data);
            })
        },
        async addEmployee({commit}, payload)
        {
            let formData = new FormData();

            formData.append('name', payload.name);
            formData.append('positionId', payload.positionId);
            formData.append('imageDto.id', payload.imageDto.id);
            formData.append('imageDto.file', payload.imageDto.file);

            await api.post("Employee/Add",formData,({data})=>{
                commit("ADD_EMPLOYEE", data);
            },{success:"تم إضافة موظف بنجاح", error:"فشل إضافة موظف"})
        },
        async updateEmployee(ctx, payload)
        {
            let formData = new FormData();

            formData.append('id', payload.id);
            formData.append('name', payload.name);
            formData.append('positionId', payload.positionId);
            formData.append('language', payload.language);
            formData.append('imageDto.id', payload.imageDto.id);
            formData.append('imageDto.file', payload.imageDto.file);
            formData.append('imageDto.path', payload.imageDto.path);

            await api.put("Employee/Update", formData, () => {
            }, { success: "تم تعديل الموظف بنجاح", error: "فشل تعديل الموظف" })
        },
        async deleteEmployee(ctx, id)
        {
            await api.delete("Employee/Delete?employeeId=" + id, ({ data }) => {
                if (data) {
                    router.push("./");
                }
            }, { confirm: "هل فعلاً تريد حذف الموظف؟", success: "تم حذف الموظف بنجاح", error: "فشل في حذف الموظف" })
        },
        async deleteMultiEmployees({ commit }, ids) {
            await api.delete("Employee/DeleteMulti", ({ data }) => {
                if (data) {
                    commit("DELETE_MULTI_EMPLOYEES", ids)
                }
            }, { confirm: "هل فعلاً تريد حذف الموظفين المحددين؟", success: "تم حذف الموظفين بنجاح", error: "فشل في حذف الموظفين" },
                ids);
        },

    }
}