import api from "@/EK-core/api";
export default {
    state: {
        statistics: {
            activitesCount: null,
            categories: [],
            categoriesCount: 0,
            categoriesName: [],
            categoryProductsCount: [],
            orders: [],
            ordersCount: 0,
            products: [],
            thisMonthActivitesCount: 0
        },
        options: {
            chart: {
                id: "vuechart-example"
            },
            xaxis: {
                categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998]
            }
        },
        series: [
            {
                name: "series-1",
                data: [30, 40, 45, 50, 49, 60, 70, 91]
            }
        ]
    },

    mutations: {
        Set_Statistics(state, payload) {
            Object.assign(state.statistics, payload);
            state.options.xaxis.categories = payload.categoriesName;
            state.series = [
                {
                    name: "",
                    data: payload.categoryProductsCount
                }
            ];
        }
    },
    actions: {
        getStatitics({ commit }) {
            api.get("Home/GetStatistics", ({ data }) => {
                commit("Set_Statistics", data);
            });
        }
    }
};
