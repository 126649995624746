import { Admin,Editor} from "@/router";
export default [
    {
        path: "customers",
        components: {
            default: () => import("@/views/admin/customers/"),
            actions: () =>
                import("@/views/admin/customers/components/search.vue")
        },
        meta: () => ({
            pageTitle: "الزبائن",
            roles: [Admin, Editor],
            layout: "vertical",
            breadcrumb: [
                {
                    text: "الزبائن",
                    active: false,
                    icon: "shopping-bag",
                    to: "/admin/customers"
                }
            ]
        })
    },
    {
        path: "customers/:id",
        name: "customer details",
        components: {
            default: () => import("@/views/admin/customers/details.vue")
        },
        props: {
            default: true
        },
        meta: () => ({
            pageTitle: "تفاصيل  الزبون",
            roles: [Admin, Editor],
            layout: "vertical",
            breadcrumb: [
                {
                    text: "الزبائن",
                    icon: "shopping-bag",
                    to: "./",
                    active: false
                },
                {
                    text: "تفاصيل الزبون",
                    active: true
                }
            ]
        })
    }
];
