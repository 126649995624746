import { Admin } from "@/router";
export default [
    {
        path: "accounts",
        name: "accounts",
        components: {
            default: () => import("@/views/admin/accounts/index.vue"),
            actions: () => import("@/views/admin/accounts/components/action-account.vue"),
        },
        meta: () => ({
            pageTitle: "حسابات المستخدمين",
            layout: "vertical",
            roles: [Admin],
            breadcrumb: [
                {
                    text: "حسابات المستخدمين",
                    active: true
                }
            ],
        }),
    },
    {
        path: "accounts/:id",
        name: "account details",
        component: () => import("@/views/admin/accounts/pages/details.vue"),
        props: true,
        meta: () => ({
            pageTitle: "حسابات المستخدمين",
            layout: "vertical",
            roles: [Admin],
            breadcrumb: [
                {
                    text: "حسابات المستخدمين",
                    active: false,
                    to:"/admin/accounts"
                },
                {
                    text: "تفاصيل",
                    active: true
                }
            ],
        }),
    },
]