import Vue from "vue";
import FeatherIcon from "@core/components/feather-icon/FeatherIcon.vue";
import Unicon from "vue-unicons";
import {
    uniCube,
    uniBuilding,
    uniTrash,
    uniMoon,
    uniBell,
    uniCircle,
    uniSun,
    uniPlus,
    uniHomeAlt,
    uniTrashAlt,
    uniCalendarAlt,
    uniUser,
    uniDesktop,
    uniHorizontalAlignRight,
    uniMap,
    uniNewspaper,
    uniServerAlt,
    uniLayerGroup,
    uniSearch,
    uniTimes,
    uniFileBlank,
    uniFolderOpen,
    uniFolder,
    uniAngleRight,
    uniAngleLeft,
    uniAngleUp,
    uniEllipsisV,
    uniSync,
    uniAngleDown,
    uniPen,
    uniArrowLeft,
    uniShoppingCartAlt,
    uniShoppingCart,
    uniWhatsapp,
    uniFacebookF,
    uniInstagram,
    uniBehance,
    uniLinkedinAlt,
    uniInvoice,
    uniMultiply,
    uniDollarAlt,
    uniBookmark,
    uniUsersAlt,
    uniBagAlt,
    uniSwatchbook,
    uniWheelBarrow,
    uniShoppingBag,
    uniUserCircle,
    uniClipboardNotes,
    uniUserSquare,
    uniVerticalDistributionCenter,
    uniCog,
    uniPaperclip,
    uniLink,
    uniEnvelope,
    uniMapMarker,
    uniEnvelopeAlt,
    uniArrowRight,
    uniArrowDown,
    uniCheck,
    uniTablet,
    uniPhone,
    uniMinus,
    uniFilesLandscapes,
    uniGift,
    uniPercentage,
    uniCommentExclamation,
    uniQrcodeScan,
    uniPrint,
    uniUserLocation,
    uniTimesCircle,
    uniCheckCircle,
    uniCopy,
    uniBolt,
    uniMegaphone,
    uniImport,
    uniEye,
    uniBriefcaseAlt,
    uniBox,
    uniTornado,
} from "vue-unicons/src/icons";

Unicon.add([
    // for theme
    uniCube,
    uniBuilding,
    uniTrash,
    uniPlus,
    uniLayerGroup,
    uniServerAlt,
    uniDesktop,
    uniNewspaper,
    uniMap,
    uniSun,
    uniHorizontalAlignRight,
    uniMoon,
    uniBell,
    uniCircle,
    uniHomeAlt,
    uniFileBlank,
    uniCalendarAlt,
    uniSearch,
    uniUser,
    uniTrashAlt,
    uniTimes,
    uniFolderOpen,
    uniFolder,
    uniAngleRight,
    uniAngleUp,
    uniAngleLeft,
    uniAngleDown,
    uniEllipsisV,
    uniSync,
    uniArrowLeft,
    uniPen,
    // end,
    uniShoppingCart,
    uniShoppingCartAlt,
    uniInvoice,
    uniMultiply,
    uniDollarAlt,
    uniBookmark,
    uniUsersAlt,
    uniBagAlt,
    uniSwatchbook,
    uniWheelBarrow,
    uniShoppingBag,
    uniUserCircle,
    uniClipboardNotes,
    uniUserSquare,
    uniVerticalDistributionCenter,
    uniPaperclip,
    uniLink,
    uniFacebookF,
    uniEnvelope,
    uniInstagram,
    uniMapMarker,
    uniEnvelopeAlt,
    uniArrowRight,
    uniArrowDown,
    uniCheck,
    uniTablet,
    uniPhone,
    uniMinus,
    uniFilesLandscapes,
    uniGift,
    uniPercentage,
    uniCommentExclamation,
    uniQrcodeScan,
    uniPrint,
    uniUserLocation,
    uniTimesCircle,
    uniCheckCircle,
    uniCopy,
    uniBolt,
    uniMegaphone,
    uniImport,
    uniEye,
    uniBriefcaseAlt,
    uniWhatsapp,
    uniFacebookF,
    uniInstagram,
    uniLinkedinAlt,
    uniBehance,
    uniCog,
    uniEnvelope,
    uniBox,
    uniAngleLeft,
    uniAngleRight,
    uniTornado
]);
Vue.use(Unicon);
Vue.component(FeatherIcon.name, FeatherIcon);
