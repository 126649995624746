import store from "@/store"
import api from "@api"
export default {
    state: {
        services: []
    },
    mutations: {
        Fetch_Services_Website(state, data) {
            state.services = data
        },
    },
    actions: {
        fetchServiesWebsite({commit}) {
            api.get('Service/GetAll?Language=' + store.state.app.lang, ({data}) => {
                commit('Fetch_Services_Website', data)
            })
        },
    }
}