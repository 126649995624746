import global from './global'
import webServices from './services'
import webActivities from './activities'
import websiteAccounts from './accounts'
import webProducts from './products'
import webCart from './cart'

export default {
    global,
    webServices,
    webActivities,
    websiteAccounts,
    webProducts,
    webCart
}