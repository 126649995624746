import api from "@api";
import router from "@/router";
import { nullGuid } from "@/EK-core/util/global";
export default {
    state: {
        user: {
            firstName: "",
            id: 0,
            lastName: ""
        },
        websiteWebsiteAccountList: [],
        websiteAccountDto: {
            id: nullGuid,
            fullName: "",
            password: "",
            confirmPassword: "",
            mobileNumber: "",
            email: "",
            address: "",
            countryId: null
        },
        customerOrders: {
            id: nullGuid,
            fullName: "",
            mobileNumber: "",
            email: "",
            address: "",
            dateCreated: "",
            password: "",
            confirmPassword: "",
            dOB: "",
            countryId: null,
            countryName: "",
            orders: []
        },
        signInDto: {
            username: "",
            password: "",
            rememberMe: false,
            hashedCart: ""
        },
        rulesAgree: false,
        rolesList: [],
        selectedSubOrdersIds: {
            value: []
        }
    },
    mutations: {
        Reverse_Rules_Agreement(state, is) {
            state.rulesAgree = is;
        },
        Get_Website_Account_List(state, payload) {
            state.websiteWebsiteAccountList = payload.users;
        },
        Get_Customer_Orders(state, payload) {
            state.customerOrders = payload.customerDto;
        },
        Sign_In(state) {
            Object.assign(
                state.user,
                JSON.parse(localStorage.getItem("customerData"))
            );
        },
        Reset_User(state) {
            Object.assign(state.user, {
                firstName: "",
                id: 0,
                lastName: ""
            });
        },
        Update_Website_Account(state, payload) {
            Object.assign(state.websiteAccountDto, payload);
        },
        Update_Customer_Orders(state, payload) {
            Object.assign(state.customerOrders, payload.customerDto);
        },
        Update_Login_Dto(state, payload) {
            Object.assign(
                state.websiteWebsiteAccountList.find(ac => ac.id == payload.id),
                payload
            );
        },
        Reset_Website_Account_Dto(state) {
            Object.assign(state.websiteAccountDto, {
                id: nullGuid,
                fullName: "",
                password: "",
                confirmPassword: "",
                mobileNumber: "",
                email: "",
                address: "",
                countryId: null
            });
        },
        Reset_Customer_Details_Dto(state) {
            Object.assign(state.customerOrders, {
                id: nullGuid,
                fullName: "",
                mobileNumber: "",
                email: "",
                address: "",
                dateCreated: "",
                password: "",
                confirmPassword: "",
                dOB: "",
                countryId: null,
                countryName: "",
                orders: []
            });
        },
        Rest_SignIn_Dto(state) {
            Object.assign(state.signInDto, {
                username: "",
                password: "",
                rememberMe: false,
                hashedCart: ""
            });
        },
        SET_TOKEN(state) {
            Object.assign(
                state.user,
                JSON.parse(localStorage.getItem("customerData"))
            );
        }
    },
    actions: {
        signUp({ commit }, payload) {
            api.post(
                "Account/SignUp",
                payload,
                res => {
                    commit("Reset_Website_Account_Dto");
                    router.push("/auth-signin");
                    console.log(res);
                },
                {
                    success: "تم إضافة الحساب بنجاح",
                    error: "فشل إضافة الحساب"
                }
            );
        },
        updateCustomer({ commit }, payload) {
            api.post(
                "Website/UpdateCustomer",
                payload,
                ({ data }) => {
                    commit("Update_Customer_Orders", data);
                },
                {
                    success: "تم تعديل الحساب بنجاح",
                    error: "فشgetCustomerOrders تعديل الحساب"
                }
            );
        },
        getWebsiteCustomerDetails({ commit }, id) {
            api.get(
                "Website/GetCustomerDetails?customerId=" + id,
                ({ data }) => {
                    commit("Reset_Customer_Details_Dto");
                    commit("Get_Customer_Orders", data);
                }
            );
        }
    }
};
