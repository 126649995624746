import { Admin } from "@/router";
export default [
    {
        path: "categories/:id?",
        name: "categories",
        components: {
            default: () => import("@/views/admin/categories/index.vue"),
            actions: () => import("@/views/admin/categories/components/categories-button-action.vue"),
        },
        props: {
            default: true
        },
        meta: () => ({
            pageTitle: "التصنيفات",
            roles: [Admin],
            layout: "vertical",
            breadcrumb: [
                {
                    text: "التصنيفات",
                    active: true
                }
            ],
        }),
    },
]