import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

// Routes
import { canNavigate } from "@/libs/acl/routeProtection";
import { isUserLoggedIn } from "@/auth/utils";

import website from "./website";
import admin from "./admin";

// import docs from "./docs";

Vue.use(VueRouter);

export const All = "all";
export const Admin = "Admin";
export const Customer = "Customer";
export const Support = "Support";
export const Editor = "Editor";

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    scrollBehavior() {
        store.dispatch("app/setLoading", false);
        return { x: 0, y: 0 };
    },
    routes: [
        {
            path: "/",
            component: () => import("@/views/website"),
            meta: () => ({
                roles: [All],
            }),
            children: website
        },
        {
            path: '/admin',
            components: {
                default: () => import('@/views/admin'),
                'bread-actions': () => import('@/views/admin/admin-bread-actions.vue'),
            },
            children: admin
        },
        // docs,
        {
            path: "/login",
            name: "login",
            component: () => import("@/views/Login.vue"),
            meta: () => ({
                pageTitle: "login",
                roles: [All],
                redirectIfLoggedIn: true,
            }),
        },
        {
            path: "/access-denied",
            name: "access-denied",
            component: () => import("@/views/error/NotAuthorized.vue"),
            meta: () => ({
                pageTitle: "access-denied",
                roles: [All],
            }),
        },
        {
            path: "/server-error",
            name: "server error",
            component: () => import("@/views/error/server-error.vue"),
            meta: () => ({
                pageTitle: "conection-error",
                roles: [All],
            }),
        },
        {
            path: "/error-404",
            name: "error 404",
            meta: () => ({
                pageTitle: "not-found",
                roles: [All],
            }),
            component: () => import("@/views/error/error-404.vue"),
        },
        {
            path: "*",
            redirect: "/error-404",
        },
    ],
});

router.beforeEach((to, _, next) => {
    const isLoggedIn = isUserLoggedIn();
    document.title = !to.meta(to).breadcrumb
        ? !to.meta(to).pageTitle
        ? to.meta(to).title
        : to.meta(to).pageTitle
        : to.meta(to).breadcrumb[0].text;
    if (!canNavigate(to)) {
        // Redirect to login if not logged in
        if (!isLoggedIn) return next("/login");

        // If logged in => not authorized
        return next("/access-denied");
    }
    return next();
});

export default router;
