import { Admin } from "@/router";
export default [
    {
        path: "settings",
        components: {
            default: () => import("@/views/admin/settings"),
            actions: () =>
                import("@/views/admin/settings/components/search.vue")
        },
        meta: () => ({
            pageTitle: "اعدادات الموقع",
            roles: [Admin],
            layout: "vertical",
            breadcrumb: [
                {
                    text: "اعدادات الشركة",
                    active: true
                }
            ]
        }),
        children: [
            {
                path: "",
                name: "settings",
                components: {
                    default: () =>
                        import(
                            "@/views/admin/settings/components/global-settings.vue"
                        )
                },
                meta: () => ({
                    pageTitle: "اعدادات الموقع",
                    roles: [Admin],
                    layout: "vertical",
                    breadcrumb: [
                        {
                            text: "الاعدادت العامة",
                            active: true
                        }
                    ]
                })
            },
            {
                path: "contact",
                name: "contact",

                component: () =>
                    import("@/views/admin/settings/components/contact.vue"),
                meta: () => ({
                    pageTitle: "اعدادات الموقع",
                    layout: "vertical",
                    roles: [Admin],
                    breadcrumb: [
                        {
                            text: "تواصل معنا",
                            active: true
                        }
                    ]
                })
            },
            {
                path: "clients",
                name: "clients",

                component: () =>
                    import("@/views/admin/settings/components/clients.vue"),
                meta: () => ({
                    pageTitle: "اعدادات الموقع",
                    roles: [Admin],
                    layout: "vertical",
                    breadcrumb: [
                        {
                            text: "العملاء",
                            active: true
                        }
                    ]
                })
            },
            {
                path: "about",
                name: "about",
                component: () =>
                    import("@/views/admin/settings/components/ab.vue"),
                meta: () => ({
                    pageTitle: "اعدادات الموقع",
                    layout: "vertical",
                    roles: [Admin],
                    breadcrumb: [
                        {
                            text: "حولنا",
                            active: true
                        }
                    ]
                })
            },
            {
                path: "positions",
                name: "positions",
                component: () =>
                    import("@/views/admin/settings/components/positions.vue"),
                meta: () => ({
                    pageTitle: "اعدادات الموقع",
                    layout: "vertical",
                    roles: [Admin],
                    breadcrumb: [
                        {
                            text: "مناصب الموظفين",
                            active: true
                        }
                    ]
                })
            }
        ]
    }
];
