import api from "@api"
import router from "@/router";

export default {
    state:{
        orders:[],
        orderDto:{
            id:"",
            number:"",
            note:"",
            orderDate:new Date(),
            totalPrice:0,
            totalQuantity:0,
            fullName:"",
            address:"",
            mobileNumber:"",
            email:"",
            orderDetails: [],
            dateFinished: null
        }
    },
    mutations:{
        GET_ORDERS(state, payload)
        {
            state.orders = payload;
        },
        GET_ORDER_DETAILS(state, payload)
        {
            Object.assign(state.orderDto, payload);
        },
        REMOVE_RANGE_ORDERS(state, payload) {
            let MapOfIds = new Map();
            var idx;
            var tempList = [];
            for (idx = 0; idx < payload.length; idx++) {
                MapOfIds.set(payload[idx], 1);
            }
            for (idx = 0; idx < state.orders.length; idx++) {
                if (MapOfIds.has(state.orders[idx].id) === false) {
                    tempList.push(state.orders[idx]);
                }
            }
            state.orders = tempList;
        },
        FINISH_ORDER(state)
        {
            state.orderDto.dateFinished = new Date();
        }
    },
    actions: {
        async fetchOrders({ commit }) {
            await api.get("Order/GetOrders?language=0", ({ data }) => {
                commit("GET_ORDERS", data);
            });
        },
        async getOrderDetails({commit}, id)
        {
            await api.get("Order/GetById?language=0&id=" + id, ({ data }) => {
                commit("GET_ORDER_DETAILS", data);
            });

        },
        async deleteOrder(ctx, id) {
            await api.delete("Order/Remove?id=" + id, ({ data }) => {
                if (data) {
                    router.push("./");
                }
            }, { confirm: "هل فعلاً تريد حذف الطلب؟", success: "تم حذف الطلب بنجاح", error: "فشل في حذف الطلب" })
        },
        async deleteMultiOrders({ commit }, ids) {
            await api.delete("Order/RemoveRange", ({ data }) => {
                if (data) {
                    commit("REMOVE_RANGE_ORDERS", ids)
                }
            }, {
                confirm: "هل تريد فعلا حذف الطلبات المحددة",
                success: "تم حذف الطلبات بنجاح",
                error: "فشل حذف الطلبات"
            }, ids)
        },
        async finishOrder({ commit }, id) {
            await api.put("Order/Finish?id=" + id, {}, ({ data }) => {
                if (data)
                    commit("FINISH_ORDER");
            }, { success: "تم إنهاء الطلب بنجاح", error: "فشل إنهاء الطلب" })
        }
    }
}