import { getSearchResultOnRow } from "@Ekcore/util";
export default {
    state: {
        filterDto: {},
        searchDto: {
            keys: [],
            query: ""
        }
    },
    getters: {
        mainCategoriesList(state, getter, glState) {
            return glState.categories.mainCategoriesList.filter(serv => {
                return getSearchResultOnRow(state, serv);
            });
        },
        subCategoriesList(state, getter, glState) {
            return glState.categories.subCategoriesList.filter(serv => {
                return getSearchResultOnRow(state, serv);
            });
        },
        accountList(state, getter, glState) {
            return glState.accounts.accountList.filter(serv => {
                return getSearchResultOnRow(state, serv);
            });
        },
        additionsList(state, getter, glState) {
            return glState.settings.additions.filter(serv => {
                return getSearchResultOnRow(state, serv);
            });
        },
        clientsList(state, getter, glState) {
            return glState.settings.clients.filter(serv => {
                return getSearchResultOnRow(state, serv);
            });
        },
        positionsList(state, getter, glState) {
            return glState.settings.positions.filter(serv => {
                return getSearchResultOnRow(state, serv);
            });
        },
        employeesList(state, getter, glState) {
            return glState.employee.employees.filter(serv => {
                return getSearchResultOnRow(state, serv);
            });
        },
        activitiesList(state, getter, glState) {
            return glState.activity.activities.filter(serv => {
                return getSearchResultOnRow(state, serv);
            });
        },
        servicesList(state, getter, glState) {
            return glState.service.services.filter(serv => {
                return getSearchResultOnRow(state, serv);
            });
        },
        contactsList(state, getter, glState) {
            return glState.contact.contacts.filter(serv => {
                return getSearchResultOnRow(state, serv);
            });
        },
        productsList(state, getter, glState) {
            return glState.product.products.filter(serv => {
                return getSearchResultOnRow(state, serv);
            });
        },

    },
    mutations: {
        Set_filter_Dto(state, payload) {
            Object.keys(payload).forEach(key => {
                state.filterDto[key] = payload[key];
            });
        },
        Set_Search_Dto(state, payload) {
            Object.assign(state.searchDto, payload);
        },
        Reset_Search_Dto(state) {
            Object.assign(state.searchDto, {
                keys: [],
                query: ""
            });
        },
        Reset_filter_Dto(state) {
            Object.assign(state.filterDto, {});
        }
    }
};
