import api from "@api";
import router from "@/router";
import store from "@/store";
export default {
    state: {
        activities: [],
        activityDto: {
            id: "",
            title: "",
            description: "",
            date: null,
            language: 0,
            documents: [],
            documentIds:[]
        }
    },
    mutations: {
        GET_ACTIVITIES(state, payload) {
            state.activities = payload;
        },
        GET_ACTIVITY_DETAILS(state, payload) {
            Object.assign(state.activityDto, payload);
        },
        RESET_ACTIVITY_DTO(state) {
            state.activityDto = {
                id: "",
                title: "",
                description: "",
                date: null,
                language: 0,
                documents: []
            }
        }
    },
    actions: {
        async fetchActivities({ commit }) {
            await api.get("Activity/GetAll?language="+store.state.app.dashLang, ({ data }) => {
                commit("GET_ACTIVITIES", data);
            })
        },
        async getActivityDetails({ commit }, id) {
            await api.get(`Activity/GetById?id=${id}&language=${store.state.app.dashLang}`, ({ data }) => {
                commit("GET_ACTIVITY_DETAILS", data);
            })
        },
        async addActivity(ctx, payload) {
            let formData = new FormData();

            formData.append('title', payload.title);
            formData.append('description', payload.description);
            var index = 0;
            for (var doc of payload.documents) {
                formData.append("documents[" + index + "].id", doc.id);
                formData.append("documents[" + index + "].path", doc.path);
                formData.append("documents[" + index + "].file", doc.file);
                index++;
            }
            await api.post("Activity/Add", formData, () => {
                router.push("/admin/activities");
            }, { success: "تم إضافة نشاط بنجاح", error: "فشل إضافة نشاط" })
        },
        async updateActivity(ctx, payload) {
            let formData = new FormData();
            formData.append('id', payload.id);
            formData.append('title', payload.title);
            formData.append('description', payload.description);
            formData.append('language', payload.language);
            var Index = 0;
            for (var id of payload.documentIds) {
                formData.append("documentIds[" + Index + "]", id);
                Index++;
            }
            var documentsIndex = 0;
            for (var doc of payload.documents) {
                formData.append("documents[" + documentsIndex + "].id", doc.id);
                formData.append("documents[" + documentsIndex + "].path", doc.path);
                formData.append("documents[" + documentsIndex + "].file", doc.file);
                documentsIndex++;
            }

            await api.put("Activity/Update", formData, () => {
            }, { success: "تم تعديل نشاط بنجاح", error: "فشل تعديل نشاط" })
        },
        async deleteActivity(ctx, id){
            api.delete(
                "Activity/Remove?id=" + id,
                ({ data }) => {
                    if (data) {
                        router.push("./");
                    }
                },{ confirm:"هل فعلاً تريد حذف النشاط؟", success:"تم حذف النشاط بنجاح", error:"فشل في حذف النشاط" }
            );
        }
    }
}