import { Admin } from "@/router";
export default [
    {
        path: "contacts",
        name: "contacts",
        components: {
            default: () => import("@/views/admin/contact/index.vue"),
            'actions':() => import("@/views/admin/contact/pages/search.vue"),
        },
        meta: () => ({
            pageTitle: "بريد الشركة",
            layout: "vertical",
            roles: [Admin],
            breadcrumb: [
                {
                    text: "بريد الشركة",
                    active: true
                }
            ],
        }),
    },
    {
        path: "contacts/:id",
        name: "contact Details",
        component: () => import("@/views/admin/contact/pages/details.vue"),
        props: true,
        meta: () => ({
            pageTitle: "بريد الشركة",
            layout: "vertical",
            roles: [Admin],
            breadcrumb: [
                {
                    text: "بريد الشركة",
                    active: false,
                    to:"/admin/contacts"
                },
                {
                    text: "تفاصيل",
                    active: true
                }
            ],
        }),
    },
]