import { Admin } from "@/router";
export default [{
    path: "/admin/orders",
    name: "orders",
    components: {
        default: () => import("@/views/admin/order"),
        "bread-actions": () =>
            import(
                "@/views/admin/order"
            )
    },

    meta: () => ({
        pageTitle: "الطلبات",
        roles: [Admin],
        layout: "vertical",
        breadcrumb: [
            {
                text: "الطلبات",
                icon: "wheel-barrow",
                active: true
            }
        ]
    })
},
//orders details
{
    path: "/admin/orders/:id",
    name: "order details",
    components: {
        default: () => import("@/views/admin/order/details.vue")
    },
    props: {
        default: true
    },
    meta: () => ({
        pageTitle: "تفاصيل الطلبية",
        roles: [Admin],
        layout: "vertical",
        breadcrumb: [
            {
                text: "الطلبات",
                icon: "wheel-barrow",
                to: "./",
                active: false
            },
            {
                text: "تفاصيل الطلب",
                active: true
            }
        ]
    })
}]