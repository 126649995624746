import api from "@api";
//import router from "@/router";

export default {
    state:{
        customers:[],
        customerDto:{
            id:"",
            fullName:"",
            mobileNumber:"",
            email:"",
            address:"",
            countryId:"",
            countryName:"",
            dataCreated:"",
            orders:[]
        }
    },
    mutations:{
        GET_CUSTOMERS(state, payload)
        {
            state.customers = payload;
        },
        GET_CUSTOMER_DETAILS(state, payload)
        {
            Object.assign(state.customerDto, payload);
        }
    },
    actions:{
        async fetchCustomers({commit})
        {
            await api.get("Account/GetCustomers",({data}) => {
                commit("GET_CUSTOMERS", data)
            });
        },
        async getCustomerDetails({ commit }, id) {
            await api.get("Account/GetCustomerDetails?customerId=" + id,
                ({ data }) => {
                    commit("GET_CUSTOMER_DETAILS", data);
                }
            );
        }
    }
}