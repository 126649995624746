import { Admin,Editor } from "@/router";
import { nullGuid } from '@/EK-core/util/global';
export default [

    {
        path: "/admin/activities",
        name: "activities",
        components: {
            default: () =>
                import("@/views/admin/activity"),
            "actions": () =>
                import(
                    "@/views/admin/activity/components/activity-action-button.vue"
                )
        },
        props: {
            default: true,
            actions: true
        },
        meta: () => ({
            pageTitle: "النشاطات",
            layout: "vertical",
            roles: [Admin, Editor],
            breadcrumb: [
                {
                    text: "النشاطات",
                    icon: "invoice",
                    active: true
                }
            ]
        })
    },
    {
        path: "/admin/activities/:id",
        name: "activity details",
        components: {
            default: () =>
                import(
                    "@/views/admin/activity/activity-action.vue"
                )
        },
        props: {
            default: true
        },
        meta: (route) => ({
            pageTitle: "تفاصيل النشاط",
            layout: "vertical",
            roles: [Admin, Editor],
            breadcrumb: [
                {
                    text: "النشاطات",
                    icon: "invoice",
                    to: "/admin/activities",
                    active: false
                },
                {
                    text: route.params && route.params.id == nullGuid
                            ? "إضافة نشاط"
                            : "تعديل نشاط",
                    active: true
                }
            ]
        })
    },

]