import { nullGuid } from "@/EK-core/util/global";
import store from "@/store";
import api from "@api";
import axiosIns from "@axios";

export default {
    state: {
        activities: [],
        activityDetails: {
            id: nullGuid,
            title: "",
            description: "",
            date: new Date(),
            imagePath:""
        },
        otherActivities:[]
    },
    mutations: {
        Fetch_Activities_Website(state, payload) {
            state.activities = payload;
        },
        GET_WEBSITE_ACTIVITY_DETAILS(state, payload) {
            Object.assign(state.activityDetails, payload.activityDetails);
            state.otherActivities = payload.otherActivities;
        }
    },
    actions: {
        async fetchWebsiteActivities({ commit }) {
            await api.get("Website/GetActivities?language=" + store.state.app.lang, ({ data }) => {
                commit("Fetch_Activities_Website", data);
            });
        },
        async getWebsiteActivityDetails({ commit }, id) {
            await axiosIns.post('Website/GetActivityDetails',
                {
                    id: id,
                    count: 3,
                    language: store.state.app.lang
                }).then(({ data }) => {
                    commit("GET_WEBSITE_ACTIVITY_DETAILS", data);
                });
        }
    }
};
