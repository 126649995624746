import settings from "./settings";
import accounts from "./accounts";
import categories from "./categories";
import contact from "./contact";
import activity from "./activity";
import service from "./service";
import employee from "./employee";
import customers from "./customers";
import product from "./product";
import order from "./order";
import { Admin } from "@/router";
export default [
    {
        path: "",
        redirect: "/admin/home"
    },
    {
        path: "home",
        name: "home",
        component: () => import("@/views/admin/home"),
        meta: () => ({
            pageTitle: "الرئيسية",
            roles: [Admin],
            layout: "vertical",
            breadcrumb: [
                {
                    text: "الرئيسية",
                    active: true
                }
            ]
        })
    },
    ...settings,
    ...accounts,
    ...categories,
    ...contact,
    ...activity,
    ...service,
    ...employee,
    ...customers,
    ...product,
    ...order
];
