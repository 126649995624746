import api from "@/EK-core/api";
import { nullGuid } from "@/EK-core/util/global";
import store from "@/store";
export default {
    state: {
        clientDto: {
            name: "",
            file: ""
        },
        settingKeys: [],
        clients: [],
        additions: [],
        additionDto: { name: "", attributes: [], language:0 },
        aboutDto: {
            goals: "",
            vision: "",
            goalsImage: {
                file: null
            },
            visionImage: {
                file: null
            }
        },
        contactDto: {
            mobileNumber: "",
            mobileNumber2: "",
            phoneNumber: "",
            phoneNumber2: "",
            facebookLink: "",
            instagramLink: "",
            linkedinLink: "",
            behanceLink: "",
            whatsapp: "",
            email: "",
            workHours: ""
        },
        positions: [],
        positionDto: {
            id: nullGuid,
            name: "",
            language:0
        },
        currency: [],
        currencyDto: {},
        workHours: {
            dayFrom: "",
            dayTo: "",
            hourFrom: "",
            hourTo: "",
            timeFrom: "",
            timeTo: ""
        }
    },
    mutations: {
        Set_Search_Keys(state, keys) {
            state.settingKeys = keys;
        },
        Set_CLients_List(state, payload) {
            state.clients = payload;
        },
        Update_Client(state, payload) {
            let index = state.clients.findIndex(el => el.id == payload.id);
            Object.assign(state.clients[index], payload);
        },
        Add_Client(state, payload) {
            state.clients.unshift(payload);
        },
        Set_Client_Dto(state, payload) {
            if (!payload) state.clientDto = { name: "", file: "", image: "" };
            else state.clientDto = payload;
        },
        Delete_Client(state, payload) {
            state.clients = state.clients.filter(el => el.id != payload.id);
        },
        Delete_Mutli_Clients(state, payload) {
            payload.forEach(element => {
                state.clients = state.clients.filter(el => el.id != element);
            });
        },
        //Additions
        Set_Additions(state, payload) {
            state.additions = payload;
        },
        Set_Addition_Dto(state, payload) {
            if (payload) state.additionDto = payload;
            else state.additionDto = { name: "", attributes: [], language:0 };
        },
        Add_Addition(state, payload) {
            state.additions.unshift(payload);
        },
        Update_Addition(state, payload) {
            Object.assign(
                state.additions[
                    state.additions.findIndex(el => el.id == payload.id)
                ],
                payload
            );
        },
        Delete_Multi_Additions(state, payload) {
            payload.forEach(element => {
                state.additions = state.additions.filter(
                    el => el.id != element
                );
            });
        },
        Delete_Addition(state, payload) {
            state.additions = state.additions.filter(el => el.id != payload);
        },

        //About
        Set_About(state, payload) {
            state.aboutDto = payload;
        },
        //Global Settings
        Set_Settings(state, payload) {
            state.contactDto = payload;
            const words = payload.workHours.split("-");
            (state.workHours.dayFrom = words[4]),
                (state.workHours.dayTo = words[5]),
                (state.workHours.hourFrom = words[3]),
                (state.workHours.hourTo = words[1]),
                (state.workHours.timeFrom = words[2]),
                (state.workHours.timeTo = words[0]);
        },
        //Positions
        GET_POSITIONS(state, payload) {
            state.positions = payload;
        },
        ADD_POSITION(state, payload) {
            state.positions.unshift(payload);
        },
        UPDATE_POSITION(state, payload) {
            let index = state.positions.findIndex(
                item => item.id == payload.id
            );
            Object.assign(state.positions[index], payload);
        },
        DELETE_POSITION(state, id) {
            let index = state.positions.findIndex(item => item.id == id);
            state.positions.splice(index, 1);
        },
        DELETE_MULTI_POISTIONS(state, ids) {
            ids.forEach(element => {
                state.positions = state.positions.filter(
                    item => item.id != element
                );
            });
        },
        SET_POSITION_DTO(state, payload) {
            if (payload) {
                Object.assign(state.positionDto, payload);
            } else {
                state.positionDto = {
                    id: nullGuid,
                    name: ""
                };
            }
        },
        //Currency
        Set_Currency(state, payload) {
            state.currency = payload;
        }
    },
    actions: {
        getClients({ commit }) {
            api.get("Client/Fetch", ({ data }) => {
                commit("Set_CLients_List", data);
            });
        },
        addClient({ commit }, payload) {
            api.post(
                "Client/AddWithImage",
                payload,
                ({ data }) => {
                    commit("Add_Client", data);
                },
                {
                    success: "تمت إاضافة العميل بنجاح",
                    error: "فشلت الاضافة .. يرجى إعادة المحاولة"
                }
            );
        },
        deleteClient({ commit }, payload) {
            api.delete(
                `Client/Delete?id=${payload}`,
                ({ data }) => {
                    commit("Delete_Client", data);
                },
                {
                    confirm: "هل تريد  بالتأكيد حذف العميل؟",
                    success: "تم الحذف بنجاح",
                    error: "فشل الحذف"
                },
                payload
            );
        },
        deleteMultiClients({ commit }, payload) {
            api.delete(
                "Client/DeleteRange",
                res => {
                    if (res.status == 200)
                        commit("Delete_Mutli_Clients", payload);
                },
                {
                    confirm: "هل تريد  بالتأكيد حذف  العناصر؟",
                    success: "تم الحذف بنجاح",
                    error: "فشل الحذف"
                },
                payload
            );
        },
        updateClient({ commit }, payload) {
            api.put(
                "Client/ModifyWithImage",
                payload,
                ({ data }) => {
                    commit("Update_Client", data);
                },
                {
                    success: "تم التعديل بنجاح",
                    error: "فشل التعديل"
                }
            );
        },

        //Additions
        getAdditions({ commit }) {
            api.get(`Additional/GetAll?Language=${store.state.app.dashLang}`, ({ data }) => {
                commit("Set_Additions", data);
            });
        },
        addAddition({ commit }, payload) {
            api.post(
                "Additional/Add",
                payload,
                ({ data }) => {
                    commit("Add_Addition", data);
                },
                {
                    success: "تمت اضافة المجموعة بنجاح ",
                    error: "فشلت الاضافة .. يرجى إعادة المحاولة "
                }
            );
        },
        updateAddition({ commit }, payload) {
            payload.language = store.state.app.dashLang;
            api.post(
                "Additional/Update",
                payload,
                ({ data }) => {
                    commit("Update_Addition", data);
                },
                {
                    success: "تمت تعديل المجموعة بنجاح ",
                    error: "فشل التعديل.. يرجى إعادة المحاولة "
                }
            );
        },
        deleteMultiAdditions({ commit }, payload) {
            api.delete(
                "Additional/RemoveRange",
                res => {
                    if (res.data) commit("Delete_Multi_Additions", payload);
                },
                {
                    confirm: "هل تريد بالتأكيد حذف الاضافات ؟",
                    success: "تم حذف الاضافات بنجاح",
                    error: "فشل الحذف .. يرجى إعادة المحاولة"
                },
                payload
            );
        },
        deleteAddition({ commit }, payload) {
            api.delete(
                `Additional/Remove?id=${payload}`,
                res => {
                    if (res.data) commit("Delete_Addition", payload);
                },
                {
                    confirm: `هل تريد بالتأكيد حذف الاضافة ؟`,
                    success: "تم حذف الاضافة بنجاح",
                    error: "فشل الحذف .. يرجى إعادة المحاولة"
                },
                payload
            );
        },
        //About us
        getAbout({ commit }) {
            api.get("About/GetAbout?Language="+store.state.app.dashLang, ({ data }) => {
                commit("Set_About", data);
            });
        },
        setAbout(_, payload) {
            api.post("About/SetAbout", payload, () => {}, {
                success: "تم تحديث القسم",
                error: "فشل التحديث .. يرجى إعادة المحاولة"
            });
        },
        //contact
        getSettings({ commit }) {
            api.get("Settings/GetSettings", ({ data }) =>
                commit("Set_Settings", data)
            );
        },
        setSettings(_, payload) {
            api.post("Settings/SetSettings", payload, () => {}, {
                success: "تم تحديث معلومات التواصل",
                error: "فشل التحديث .. يرجى إعادة المحاولة"
            });
        },
        setWorkTime(_, payload) {
            api.post("Settings/SetSettings", payload, () => {}, {
                success: "تم تحديث وقت العمل",
                error: "فشل التحديث .. يرجى إعادة المحاولة"
            });
        },
        //Positions
        async getPositions({ commit }) {
            await api.get("Position/PositionsList?language="+store.state.app.dashLang, ({ data }) =>
                commit("GET_POSITIONS", data)
            );
        },
        async addPosition({ commit }, payload) {
            await api.post(
                "Position/AddWithTranslate",
                payload,
                ({ data }) => {
                    commit("ADD_POSITION", data);
                },
                { success: "تم إضافة منصب بنجاح", error: "فشل إضافة منصب" }
            );
        },
        async updatePosition({ commit }, payload) {
            payload.language = store.state.app.dashLang;
            await api.put(
                "Position/UpdatePosition",
                payload,
                ({ data }) => {
                    commit("UPDATE_POSITION", data);
                },
                { success: "تم تعديل المنصب بنجاح", error: "فشل تعديل المنصب" }
            );
        },
        async deletePosition({ commit }, id) {
            await api.delete(
                "Position/Delete?id=" + id,
                ({ data }) => {
                    if (data) {
                        commit("DELETE_POSITION", id);
                    }
                },
                {
                    confirm: "هل تريد بالتأكيد حذف المنصب؟",
                    success: "تم حذف المنصب بنجاح",
                    error: "فشل الحذف .. يرجى إعادة المحاولة"
                }
            );
        },
        async deleteMultiPositions({ commit }, ids) {
            await api.delete(
                "Position/DeleteMulti",
                ({ data }) => {
                    if (data) commit("DELETE_MULTI_POISTIONS", ids);
                },
                {
                    confirm: "هل تريد بالتأكيد حذف المناصب المحددة ؟",
                    success: "تم حذف المناصب المحددة بنجاح",
                    error: "فشل الحذف .. يرجى إعادة المحاولة"
                },
                ids
            );
        },
        //Currency
        getCurrency({ commit }) {
            api.get("Currency/Fetch", ({ data }) => {
                commit("Set_Currency", data);
            });
        },
        updateCurrency(_, payload) {
            api.put("Currency/Modify", payload, () => {}, {
                success: "تم تعديل العملات ",
                error: "فشل التعديل ..يرجى إعادة المحاولة"
            });
        }
    }
};
