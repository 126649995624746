import { nullGuid } from "@/EK-core/util/global";
import axiosIns from "@/libs/axios";
import store from "@/store";
import api from "@api";

export default {
    state: {
        webCategories: [],
        webCategoryDto: {
            id: "",
            name: "",
            description: "",
            imagePath: "",
            childCategory: []
        },
        products: [],
        filter: {
            search: "",
            sort: { id: 1, name: "upprice" }
        },
        categoryList: [],
        subCategoryList: [],
        sortOptionsList: [
            {
                id: 1,
                name: "upprice"
            },
            {
                id: 2,
                name: "downprice"
            },
            {
                id: 3,
                name: "upname"
            },
            {
                id: 4,
                name: "downname"
            }
        ],
        productDto: {
            id: nullGuid,
            name: "",
            mainCategory: "",
            description: "",
            price: 0,
            limit: 0,
            foodSuitability: false,
            uses: [],
            measurements: [],
            addons: [],
            images: [],
            path3D: null
        },
        cartDto: {
            name: "",
            mainCategory: "",
            measurement: null,
            id: null,
            quantity: 0,
            addons: []
        }
    },
    getters: {
        webProducts({ products, filter }) {
            return products
                .sort(function(a, b) {
                    if (filter.sort.id == 2) return b.minCost - a.minCost;
                    else if (filter.sort.id == 1) return a.minCost - b.minCost;
                    else if (filter.sort.id == 4) {
                        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
                        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }
                    } else if (filter.sort.id == 3) {
                        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
                        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
                        if (nameA > nameB) {
                            return -1;
                        }
                        if (nameA < nameB) {
                            return 1;
                        }
                    }
                })
                .filter(pro => {
                    return (
                        pro.name.indexOf(filter.search) != -1 || !filter.search
                    );
                });
        },
        productDetailsTotalPrice({ cartDto, productDto }, _, { global }) {

            let country = global.locate.countryCode;
            let msr = productDto.measurements.find(
                pr => pr.id == cartDto.measurement
            );
            let price;
            if (msr) {
                price = country == "SY" ? msr.syrianValue : msr.dollarValue;
            }
            let addonsCounter = 0;
            if (cartDto.measurement) {
                if (cartDto.addons.length) {
                    cartDto.addons.forEach(element => {
                        addonsCounter +=
                            country == "SY"
                                ? element.syrianValue
                                : element.dollarValue;
                    });
                    return (price + addonsCounter) * cartDto.quantity;
                } else {
                    return price * cartDto.quantity;
                }
            }
        }
    },
    mutations: {
        Get_Categories_Website(state, data) {
            state.webCategories = data;
        },
        Get_Category_Website_Details(state, data) {
            Object.assign(state.webCategoryDto, data);
        },
        Get_Website_Product_By_Category(state, data) {
            state.products = data.product;
            state.categoryList = data.categories;
            state.subCategoryList = data.subCategories;
        },
        GET_WEBSITE_PRODUCT_DETAILS(state, payload) {
            Object.assign(state.productDto, payload);
        },
        Reset_Cart_Dto({ cartDto }) {
            Object.assign(cartDto, {
                id: null,
                name: "",
                mainCategory: "",
                measurement: null,
                quantity: 0,
                addons: []
            });
        },
        Update_Cart_Quantity_Limit(state, payload) {
            state.cartDto = {...state.cartDto, quantity: payload}

            console.log("cartDto", state.cartDto)

        },
        Reset_Product_Dto({ productDto }) {
            Object.assign(productDto, {
                id: nullGuid,
                name: "",
                mainCategory: "",
                description: "",
                price: 0,
                limit: 0,
                foodSuitability: false,
                uses: [],
                measurements: [],
                addons: [],
                images: []
            });
        }
    },
    actions: {
        getCategoriesWebsite({ commit }) {
            api.get(
                "Website/GetCategoriesWebsite?language=" + store.state.app.lang,
                ({ data }) => {
                    commit("Get_Categories_Website", data);
                }
            );
        },
        async getCategoryWebsiteDetails(_, id) {
            return await axiosIns.get(
                `Website/GetCategoriesWebsiteDetails?language=${store.state.app.lang}&Id=${id}`
            );
        },
        getWebsiteProductByCategory({ commit }, payload) {
            api.get(
                `Website/GetWebsiteProductByCategory?Language=${store.state.app.lang}&MainCategoryId=${payload.mainCategoryId}&SubCategoryId=${payload.subCategoryId}`,
                ({ data }) => {
                    commit("Get_Website_Product_By_Category", data);
                }
            );
        },
        async getWebsiteProductDetails(_, id) {
            return await axiosIns.get(
                `Website/GetProductDetails?id=${id}&language=${store.state.app.lang}`
            );
        }
    }
};
