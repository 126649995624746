import api from "@api";
import { nullGuid } from "@Ekcore/util/global.js";
import router from "@/router";
export default {
    state: {
        accountList: [],
        accountDto: {
            Id: nullGuid,
            roleId: "",
            fullName: "",
            username: "",
            password: "",
            dateCreated: "",
            userType: "",
        },
        rolesList: []
    },
    mutations: {
        Get_Account_List(state, payload) {
            state.accountList = payload.users;
        },
        Get_Role_List(state, payload) {
            console.log("rolesList", payload);
            state.rolesList = payload;
        },
        Update_Account(state, payload) {
            Object.assign(state.accountDto, payload);
        },
        Update_Account_List(state, payload) {
            Object.assign(
                state.accountList.find(ac => ac.id == payload.id),
                payload
            );
        },
        Reset_Account_Dto(state) {
            Object.assign(state.accountDto, {
                Id: nullGuid,
                fullName: "",
                username: "",
                password: "",
                dateCreated: "",
                userType: "",
            });
        },
        Add_Account(state, payload) {
            state.accountList.unshift(payload);
        },
        Delete_Account(state, id) {
            state.accountList.splice(
                state.accountList.findIndex(item => item.id == id),
                1
            );
        },
        Delete_Account_List(state, ids) {
            state.accountList = state.accountList.filter(
                service => !ids.includes(service.id)
            );
        }
    },
    actions: {
        getAccountList({ commit }) {
            api.get("Account/GetUsers", ({ data }) => {
                console.log("payload", data);
                commit("Get_Account_List", data);
                commit("Get_Role_List", data.rolesList);
            });
        },
        fetchAccount({ commit }, payload) {
            api.get("Account/GetUserDetails?userId=" + payload, ({ data }) => {
                commit("Update_Account", data.userDto);
                commit("Get_Role_List", data.rolesList);
            });
        },
        addAccount({ commit }, payload) {
            api.post(
                "Account/AddUser",
                payload,
                ({ data }) => {
                    console.log("data", data)
                    commit("Add_Account", data);
                    commit("Reset_Account_Dto");
                },
                {
                    success: "تم إضافة الحساب بنجاح",
                    error: "فشل إضافة الحساب"
                }
            );
        },
        updateAccount({ commit }, payload) {
            api.put(
                "Account/UpdateUser",
                payload,
                ({ data }) => {
                    commit("Update_Account_List", data);
                },
                {
                    success: "تم تعديل الحساب بنجاح",
                    error: "فشل تعديل الحساب"
                }
            );
        },
        deleteAccount({ commit }, id) {
            api.delete(
                "Account/DeleteUser/" + id,
                ({ data }) => {
                    if (data) {
                        commit("Delete_Account", id);
                        router.push("/admin/accounts");
                    }
                },
                {
                    confirm: "هل تريد فعلا حذف الحساب",
                    success: "تم حذف الحساب بنجاح",
                    error: "فشل حذف الحساب"
                }
            );
        },
        deleteAccountList({ commit }, ids) {
            api.delete(
                "Account/DeleteUserRange",
                ({ data }) => {
                    if (data) {
                        commit("Delete_Account_List", ids);
                    }
                },
                {
                    confirm: "هل تريد فعلا حذف الحسابات المحددة",
                    success: "تم حذف الحسابات المحددة بنجاح",
                    error: "فشل حذف الحسابات المحددة "
                },
                ids
            );
        }
    }
};
