import accounts from "./accounts"
import settings from './settings'
import contact from "./contact"
import home from './home'
import activity from "./activity"
import service from "./service"
import categories from "./categories"
import employee from "./employee"
import product from "./product"
import customers from "./customers"
import order from "./order"
export default {
    accounts,
    settings,
    contact,
    activity,
    service,
    categories,
    employee,
    product,
    customers,
    home,
    order
}