import { Admin,Editor } from "@/router";
import { nullGuid } from '@/EK-core/util/global';
export default [

    {
        path: "/admin/products",
        name: "products",
        components: {
            default: () =>
                import("@/views/admin/product"),
            "actions": () =>
                import(
                    "@/views/admin/product/components/product-action-button.vue"
                )
        },
        props: {
            default: true,
            actions: true
        },
        meta: () => ({
            pageTitle: "المنتجات",
            roles: [Admin, Editor],
            layout: "vertical",
            breadcrumb: [
                {
                    text: "المنتجات",
                    icon: "box",
                    active: true
                }
            ]
        })
    },
    {
        path: "/admin/products/:id",
        name: "product details",
        components: {
            default: () =>
                import(
                    "@/views/admin/product/product-action.vue"
                )
        },
        props: {
            default: true
        },
        meta: route => ({
            roles: [Admin, Editor],
            layout: "vertical",
            breadcrumb: [
                {
                    text: "المنتجات",
                    icon: "box",
                    to: "/admin/products",
                    active: false
                },
                {
                    text: route.params && route.params.id == nullGuid
                            ? "إضافة منتج"
                            : "تعديل منتج",
                    active: true
                }
            ]
        })
    },

]